<!-- From https://www.iconfinder.com/icons/352359/filter_list_icon -->
<template>
  <svg
    class="icon filter"
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M20 36h8v-4h-8v4zm-14-24v4h36v-4h-36zm6 14h24v-4h-24v4z"/>
    <path d="M0 0h48v48h-48z" fill="none"/>
  </svg>
</template>
