<template>
  <div class="columns is-mobile pie-chart-wrapper">
    <div :class="['column', 'pie-chart', _extraChartClasses]">
      <h2 class="chart-title">
        {{ title }}
      </h2>
      <div :id="id" />
    </div>
    <div :class="['column', 'legend', _extraLegendClasses]">
      <h3 class="chart-legend">
        {{ $gettext("Legend") }}
      </h3>
      <ul>
        <li
          v-for="item in legendContent"
          :key="item.color"
        >
          <span
            class="swatch"
            :style="`background-color: ${item.color}`"
          />
          {{ item.label }}
          <span class="muted">
            {{ item.detail }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { onMounted, onUnmounted } from "vue"

  import { getPieChartDisplayer } from "@/utils/PieChart"
  import UniqueID from "@/utils/UniqueID"

  interface ChartDatum {
    label: string
    num: number
    den: number
    pct: string
  }

  interface Props {
    data: Array<ChartDatum>
    title: string
    colors?: Array<string>
    extraChartClasses?: string
    extraLegendClasses?: string
  }
  const {
    data,
    title,
    colors,
    extraChartClasses,
    extraLegendClasses,
  } = defineProps<Props>()
  const id = `pie-chart-${UniqueID()}`
  const defaultColors = ["#0B3041", "#166083", "#2191C4", "#51B4E1", "#92D0ED", "#D3ECF8"]

  // There's something funky with destructuring props with default values.
  // The defaults are in scope here in the script, but it's like the template is
  // rendered before these defaults are set. So use workaround variables.
  const _extraChartClasses = extraChartClasses
  const _extraLegendClasses = extraLegendClasses
  const _colors = colors || data.length < 4 ? [defaultColors[1], defaultColors[3], defaultColors[4]] : defaultColors
  const legendContent = data.map((datum: ChartDatum, index) => {
    return {
      color: _colors[index],
      label: datum.label,
      detail: `${datum.num.toLocaleString()} (${datum.pct})`
    }
  })
  let drawPieChart: () => void

  const chartData = data.map((item: ChartDatum) => [item.label, item.num])
  onMounted(() => {
    drawPieChart = getPieChartDisplayer(chartData, id, _colors)
    drawPieChart()
    window.addEventListener("resize", drawPieChart)
  })
  onUnmounted(() => {
    window.removeEventListener("resize", drawPieChart)
  })
</script>
<style lang="scss" scoped>
  .chart-title {
    text-align: center;
    color: black;
  }

  .chart-legend {
    font-size: 1rem;
    color: hsl(221, 14%, 71%); /* utilities.$grey-light */
    text-transform: uppercase;
  }
  .swatch {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    vertical-align: text-top;
  }
</style>
