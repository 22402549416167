<script lang="ts" setup>
  import { ref, watchEffect } from "vue"
  import { useRoute, useRouter } from "vue-router"
  import { useWindowSize } from '@vueuse/core'

  import { getMalnutritionReportData } from "@/services/Api"
  import { getSiteByCmiId, getSitesForProject } from "@/services/Site"
  import { getProjectByCmiId, getProjects } from "@/services/Project"
  import { getReportFilterSchema } from "@/schemas/Report"
  import { setCurrentViewContext } from "@/utils/GlobalState"
  import { gettext } from "@/utils/Translation"
  import { ISite } from "@/db"

  import PageLoading from "@/components/PageLoading.vue"
  import ModalWindow from "@/components/ModalWindow.vue"
  import IconFilter from "@/components/svg/IconFilter.vue"
  import IconSpinner from "@/components/svg/IconSpinner.vue"
  import ReportFilterForm from "@/components/assessments/ReportFilterForm.vue"
  import ReportMenu from "@/components/assessments/ReportMenu.vue"
  import SiteHeader from "@/components/sites/SiteHeader.vue"

  const { $gettext } = gettext
  const route = useRoute()
  const router = useRouter()
  const siteCmiId = parseInt(route.params.siteCmiId as string)
  const projectCmiId = parseInt(route.params.projectCmiId as string)
  const allColsMinWidth = 625 // Breakpoint. Should correspond with $tablet defined in _main.css

  let fields: Array<object>
  let fieldMapping: object  // Map field ids to an object mapping values to labels

  const { width } = useWindowSize()
  const data = ref(null)
  const pageReady = ref(false)
  const showModal = ref(false)
  const activeFilters = ref({}) // Data structure to be passed on to API
  const activeFilterTags = ref([]) // Presentational version of the above
  const tableIsUpdating = ref(false) // Show a spinner?
  // Machinery to only show part of the columns if the viewport is too narrow.
  const showAllChildrenCol = ref(true)
  const showMultiAssessmentChildrenCol = ref(width.value > allColsMinWidth)
  const site = ref(null as ISite)

  watchEffect(() => {
    // The minor downside to this approach is if they're on desktop and
    // resizing at narrow viewport widths, they'll keep reverting to showing
    // All children if the multi had been selected. Don't really care.
    showAllChildrenCol.value = true
    showMultiAssessmentChildrenCol.value = width.value > allColsMinWidth
  })

  function updateActiveFilterTags() {
    const results = []
    Object.entries(activeFilters.value).forEach(([fieldName, value]) => {
      if (Array.isArray(value)) {
        value.forEach(val => results.push({ label:  fieldMapping[fieldName][val], fieldName, value: val }))
      }
      else results.push({ label:  fieldMapping[fieldName][value], fieldName, value })
    })
    activeFilterTags.value = results
  }

  async function getData() {
    let project
    let sites, projects
    if (siteCmiId) {
      site.value = await getSiteByCmiId(siteCmiId)
      if (site.value) {
        setCurrentViewContext(site.value, "site")
      }
      else {
        router.push({ name: "HomeRedirect" })
      }
    }
    else if (projectCmiId) {
      project = await getProjectByCmiId(projectCmiId)
      if (project) {
        setCurrentViewContext(project, "project")
      }
      else {
        router.push({ name: "HomeRedirect" })
      }
      sites = await getSitesForProject(projectCmiId)
    }
    else {
      projects = await getProjects()
    }
    fields = getReportFilterSchema({ sites, projects })
    fieldMapping = Object.fromEntries(fields.map(field => [field.id, Object.fromEntries(field.options.map(opt => [opt.value, opt.label]))]))
    updateActiveFilterTags()
    data.value = await getMalnutritionReportData({ siteCmiId, projectCmiId })
      .catch(error => {
        // FORBIDDEN is definitely a permissions issue; NOT_FOUND could well be one as well
        // (url fishing). Just redirect them silently.
        if (error.name === "NOT_FOUND" || error.name === "FORBIDDEN") {
          router.push({ name: "HomeRedirect" })
        }
        else {
          throw error
        }
      })
    pageReady.value = true

  }

  // This is overly complicated because activeFilters has values that are either arrays or strings
  async function removeFilter(filter: { fieldName: string, value: string|number }) {
    const { fieldName, value } = filter
    tableIsUpdating.value = true
    const results =[]
    Object.entries(activeFilters.value).forEach(([f, v]) => {
      if (f === fieldName) {
        if (Array.isArray(v)) {
          const newV = v.filter(item => item !== value)
          if (newV.length) {
            results.push([f, newV])
          }
        }
        else if (v !== value) {
          results.push([f, v])
        }
      }
      else {
        results.push([f, v])
      }
    })
    activeFilters.value = Object.fromEntries(results)
    updateActiveFilterTags()
    data.value = await getMalnutritionReportData({ siteCmiId, projectCmiId }, activeFilters.value )
    tableIsUpdating.value = false
  }

  async function handleFilterFormUpdate(newFilters: object) {
    showModal.value = false
    tableIsUpdating.value = true
    activeFilters.value = Object.fromEntries(Object.entries(newFilters).filter(([fieldName, value]) => value && value !== "a"))
    data.value = await getMalnutritionReportData({ siteCmiId, projectCmiId }, activeFilters.value )
    updateActiveFilterTags()
    tableIsUpdating.value = false
  }

  getData()
</script>
<template>
  <article>
    <SiteHeader
      :site="site"
      :breadcrumb="{
        label: $gettext('Summary reports'),
        urlParams: { name: 'SiteLevelDemographicReport', params: { siteCmiId } },
      }"
    />
    <div class="columns">
      <div class="column">
        <div
          v-if="siteCmiId"
          class="super-title"
        >
          {{ $gettext("Site Reports") }}
        </div>
        <div
          v-else-if="projectCmiId"
          class="super-title"
        >
          {{ $gettext("Project Reports") }}
        </div>
        <div
          v-else
          class="super-title"
        >
          App-level Reports
        </div>
        <h1 class="title">
          {{ $gettext("Malnutrition Report") }}
        </h1>
      </div>
      <div class="column has-text-right">
        <ReportMenu
          selected="MalnutritionReport"
          :params="route.params"
        />
      </div>
    </div>
    <PageLoading v-if="!pageReady" />
    <template v-else>
      <section class="block">

        <a
          @click="showModal = true"
          class="modal-trigger"
        >
          <IconFilter />
          <strong class="text-link">
            {{ $gettext("Filters") }}
          </strong>
        </a>

        <span
          v-if="tableIsUpdating"
          class="muted ml-3"
        >
          <IconSpinner />
        </span>
        <span
          v-else-if="!activeFilterTags.length"
          class="muted ml-3"
        >
          {{ $gettext("No active filters") }}
        </span>
        <div
          v-else
          class="field is-grouped is-grouped-multiline"
        >
          <div
            v-for="filter in activeFilterTags"
            :key="filter.label"
            class="control"
          >
            <a
              @click="removeFilter(filter)"
              class="tags has-addons"
            >
              <span class="tag is-info">
                {{ filter.label }}
              </span>
              <span class="tag is-info is-delete" />
            </a>
          </div>
        </div>
        <ModalWindow
          :show-modal="showModal"
          :close-modal-function="() => showModal = false"
        >
          <ReportFilterForm
            :fields="fields"
            :active="activeFilters"
            @new-filters="handleFilterFormUpdate"
          />
        </ModalWindow>
      </section>
      <section class="block">
        <div class="table-container">
          <table :class="['table', 'is-striped', tableIsUpdating && 'table-updating']">
            <caption>
              {{ $gettext("Malnutrition Prevalence") }}
              <!-- Only show the toggles if only one of the settings is true -->
              <template v-if="showMultiAssessmentChildrenCol !== showAllChildrenCol">
                <a
                  v-if="showMultiAssessmentChildrenCol"
                  @click="() => {showMultiAssessmentChildrenCol = false; showAllChildrenCol = true }"
                  class="text-link"
                >
                  {{ $gettext("Show all children") }}
                </a>
                <a
                  v-if="showAllChildrenCol"
                  @click="() => {showAllChildrenCol = false; showMultiAssessmentChildrenCol = true }"
                  class="text-link"
                >
                  {{ $gettext("Show children with multiple assessments") }}
                </a>
              </template>
            </caption>
            <thead>
              <tr class="meta-headers">
                <td></td>
                <th v-show="showAllChildrenCol">
                  {{ $gettext("All Children") }}
                </th>
                <th
                  v-show="showMultiAssessmentChildrenCol"
                  colspan="3"
                >
                  {{ $gettext("Children with 2+ assessments") }}
                </th>
              </tr>
              <tr>
                <td></td>
                <th v-show="showAllChildrenCol">
                  {{ $gettext("Baseline") }}
                </th>
                <th v-show="showMultiAssessmentChildrenCol">
                  {{ $gettext("Baseline") }}
                </th>
                <th v-show="showMultiAssessmentChildrenCol">
                  {{ $gettext("Recent") }}
                </th>
                <th v-show="showMultiAssessmentChildrenCol">
                  {{ $gettext("Change") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, id) in data"
                :key="id"
                :class="id == 'anyMalnutritionPrevalence' && 'is-highlighted'"
              >
                <th>
                  {{ row.label }}
                </th>
                <template
                  v-for="(stat, index) in row.stats"
                  :key="index"
                >
                  <td
                    v-show="(showAllChildrenCol && index === 0) || (showMultiAssessmentChildrenCol && index)"
                    class="stat-cell "
                  >
                    <div class="pct">
                      {{ stat.pct }}
                    </div>
                    <div class="detail">
                      {{ stat.num.toLocaleString() }}/{{ stat.den.toLocaleString() }}
                    </div>
                  </td>
                </template>
                <td
                  v-show="showMultiAssessmentChildrenCol"
                  class="stat-cell"
                >
                  {{ row.change }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5" class="muted">
                  {{ $gettext("Wasting is only for assessments performed when child was under five years. Underweight for children under ten, and only considers weight-for-age.") }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
      <section class="block">
        <h2>
          {{ $gettext("About this table") }}
        </h2>
        <ul class="list">
          <li>
            {{ $gettext('"Baseline" columns include only the first assessment performed for each child.') }}
          </li>
          <li>
            {{ $gettext('The "Recent" column includes the last assessment performed for each child.') }}
          </li>
          <li>
            {{ $gettext('The first "Baseline" column includes data from all children for whom a growth assessment was performed.') }}
          </li>
          <li>
            {{ $gettext('The remaining columns include data only from children for whom at least two growth assessments have been performed.') }}
          </li>
          <li>
            {{ $gettext('"1+ indicator" captures all of the children that had one or more of the preceding malnutrition indicators.') }}
          </li>
        </ul>
      </section>
    </template>
  </article>
</template>
<style lang="scss" scoped>
  .table {
    border: 1px solid rgb(128, 128, 128);
    border-radius: 10px;
    border-collapse: separate;
    tbody th {
      padding-right: 0;
    }
  }
  @media (max-width: 400px) {
    .table thead th {
      // Contortions to fit the table on a phone width.
      font-size: 80%;
    }
  }
  table td.stat-cell .pct {
    color: #154262;
  }
  .table.table-updating tbody td,
  table.table.table-updating td.stat-cell .pct,
  table.table.table-updating td.stat-cell .detail {
    color: rgb(128, 128, 128) !important;
  }
  caption .text-link {
    display: block;
    font-size: 1rem;
  }
  .tag.is-info {
    color: white;
  }
  .modal-trigger {
    fill: #53a9d9;
    &:hover {
      fill: #f78d2d;
    }
  }
  .icon-spinner {
    width: 1em;
  }
  .icon.filter {
    width: 1.5em;
    margin-bottom: -6px;
  }
</style>
