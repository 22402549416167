import { gettext } from "@/utils/Translation"

const { $gettext } = gettext

interface SiteLike {
  name: string,
  cmiId: number,
}

export function getReportFilterSchema(locations?: { sites?: Array<SiteLike>, projects?: Array<SiteLike> }) {
  const { sites = null, projects = null } = locations || {}
  const results = [
    {
      $formkit: "radio",
      id: "d",
      name: "d",
      label: $gettext("Filter by disability status"),
      options: [
        { value: "a", label: $gettext("No filter") },
        { value: "d", label: $gettext("With a reported disability") },
        { value: "t", label: $gettext("No reported disability") },
      ],
    },
    {
      $formkit: "radio",
      id: "s",
      name: "s",
      label: $gettext("Filter by sex"),
      options: [
        { value: "a", label: $gettext("No filter") },
        { value: "f", label: $gettext("Female") },
        { value: "m", label: $gettext("Male") },
      ],
    },
    {
      $formkit: "radio",
      id: "a",
      name: "a",
      label: $gettext("Filter by age at first assessment"),
      options: [
        { value: "a", label: $gettext("No filter") },
        { value: "y", label: $gettext("Under five years") },
        { value: "o", label: $gettext("Five years and older") },
      ],
    },
  ]
  if (sites?.length) {
    results.push({
      $formkit: "checkbox",
      id: "site",
      name: "site",
      label: $gettext("Filter by site"),
      options: sites.map((site: SiteLike) => { return { value: site.cmiId, label: site.name } }),
    })
  }
  else if (projects?.length) {
    results.push({
      $formkit: "checkbox",
      id: "p",
      name: "p",
      label: $gettext("Filter by project"),
      options: projects.map((project: SiteLike) => { return { value: project.cmiId, label: project.name } }),
    })
  }
  return results
}
