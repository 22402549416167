import { scaleOrdinal } from "d3-scale"
import { select } from "d3-selection"
import { arc, pie } from "d3-shape"

// Create a pie chart, and update it on window resize.
// Args:
// - data: array of pairs (label, value)
// - selector: CSS selector of the parent element; it should have child
//    .chart
// - options: object with less common overrides, specifically:
//    margins: ({top, right, bottom, left})
//    colors: array of RGB vals
export function getPieChartDisplayer(data, selectorId: string, colors: Array<string>): () => void {
  const chart = select(`#${selectorId}`)

  const margins = { top: 0, right: 0, bottom: 0, left: 0 }

  const color = scaleOrdinal(colors)
  const pieChart = pie()
    .sort(null)
    .value((d) => d[1])

  function drawPieChart() {
    let width = document.getElementById(selectorId).offsetWidth - (margins.left + margins.right)
    let height = document.getElementById(selectorId).offsetWidth - (margins.top + margins.bottom)
    width = Math.min(width, height)
    height = width
    const radius = width / 2

    const wedge = arc()
      .outerRadius(radius - 10)
      .innerRadius(0)

    chart.select("svg").remove()
    const svg = chart.append("svg")
      .attr("width", width + margins.right + margins.left)
      .attr("height", height + margins.top + margins.bottom)
      .classed("chart pie-chart", true)
      .append("g")
      .attr("transform", `translate(${margins.left + (width / 2)}, ${margins.top + (height / 2)})`)

    const g = svg.selectAll(".arc")
      .data(pieChart(data))
      .enter().append("g")
      .attr("class", "arc")

    g.append("path")
      .attr("d", wedge)
      .style("fill", (d) => color(d.data[0]))
  }
  return drawPieChart
}
