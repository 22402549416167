<template>
  <FormKit
    type="form"
    :submit-label="$gettext('Update filters')"
    :submit-attrs="{ inputClass: 'button is-primary' }"
    :value="active"
    @submit="submitForm"
  >
    <FormKitSchema :schema="fields" />
  </FormKit>
</template>
<script lang="ts" setup>
  defineProps({
    fields: {
      type: Array<Object>,
      required: true,
    },
    active: {
      type: Object,
      default: {},
    },
  })

  const emit = defineEmits(["newFilters"])
  function submitForm(data) {
    emit("newFilters", data)
  }
</script>
